import { jsonToFormData } from 'util/jsonToFormData';
import { selectApiData, selectApiNoContent } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { ApiMessage } from 'api/const';
import { WithData } from 'api/interfaces';
import { CompanyApi } from 'api/CompanyApi';
import { DistributionApi } from 'api/DistributionApi';
import { JobApi } from 'api/JobApi';
import { PaginationApi } from 'api/PaginationApi';
import { ProjectLayoutApi } from 'api/ProjectLayoutApi';
import { ProjectSettingsApi } from 'api/ProjectSettingsApi';
import { ProjectTemplateApi } from 'api/ProjectTemplateApi';
import { AxiosInstance } from 'axios';
import { Company } from 'model/Company';
import { LifecycleType } from 'model/Lifecycle';
import { ProjectKind } from 'model/ProjectKind';
import { Moment } from 'moment';

export namespace ProjectApi {
  export type Entry = ProjectTemplateApi.Entry & {
    company?: CompanyApi.Entry & { logoFile?: File };
    circleId?: string;
    companyId?: number;
    companyName?: string;
    createdInPast?: boolean;
    dirty: boolean;
    distributions?: DistributionApi.Entry[];
    isKioskDistributionEmpty?: boolean;
    isPublished: boolean;
    jobs?: JobApi.Entry[];
    layout?: ProjectLayoutApi.Entry;
    liveStart: string;
    liveStop: string;
    pulseFrequencyId?: string;
    reportRecipients?: string[];
    responseCompleted: number;
    responsePartial: number;
    responseRate: number;
    responseStarted: number;
    settings?: ProjectSettingsApi.Entry;
    status: number;
    lifecycleType?: LifecycleType;
  };

  export type PostEntry = {
    companyId: Company.Shape['id'];
    kind: ProjectKind;
  };

  export type PaginatedEntry = PaginationApi.Entry & {
    _embedded: {
      items: ProjectApi.Entry[];
    };
  };
}

export class ProjectApi {
  constructor(private client: AxiosInstance) {}

  list(query: string): Promise<ProjectApi.PaginatedEntry> {
    return this.client
      .get(`/api/v1/project${query}`)
      .then((response) => selectResponseData(response) || []);
  }

  get(projectId: number): Promise<ProjectApi.Entry> {
    return this.client
      .get<WithData<ProjectApi.Entry>>(`/api/v1/project/${projectId}`)
      .then(selectApiData);
  }

  create(data: ProjectApi.PostEntry): Promise<ProjectApi.Entry> {
    return this.client
      .post<WithData<ProjectApi.Entry>>('/api/v1/project', data)
      .then(selectApiData);
  }

  update({
    company,
    projectId,
    logo,
    ssoEnabled,
    multiLanguageEnabled,
    customKioskCodesEnabled,
    aiSummariesEnabled,
  }: {
    company: CompanyApi.Entry;
    projectId: number;
    logo?: File;
    ssoEnabled?: boolean;
    multiLanguageEnabled?: boolean;
    customKioskCodesEnabled?: boolean;
    aiSummariesEnabled?: boolean;
  }): Promise<ProjectApi.Entry> {
    const data = jsonToFormData({
      company: {
        name: company.name,
        brandColor: company.brandColor,
        timezone: company.timezone,
        approxEmployeeNumber: company.approxEmployeeNumber,
        sectors: company.sectors,
        logoFile: logo,
        settings: {
          ...(ssoEnabled === undefined ? {} : { ssoEnabled: ssoEnabled ? 1 : 0 }),
          ...(multiLanguageEnabled === undefined
            ? {}
            : { multiLanguageEnabled: multiLanguageEnabled ? 1 : 0 }),
          ...(customKioskCodesEnabled === undefined
            ? {}
            : { customKioskCodesEnabled: customKioskCodesEnabled ? 1 : 0 }),
          ...(aiSummariesEnabled === undefined
            ? {}
            : { aiSummariesEnabled: aiSummariesEnabled ? 1 : 0 }),
        },
      },
    });

    return this.client.post(`/api/v1/project/${projectId}/company`, data).then(selectApiData);
  }

  updateSpecific({
    company,
    projectId,
    logo,
    fieldName,
    value,
  }: {
    company: CompanyApi.Entry;
    projectId: number;
    logo?: File;
    fieldName: string;
    value: boolean;
  }): Promise<ProjectApi.Entry> {
    const data = jsonToFormData({
      company: {
        name: company.name,
        brandColor: company.brandColor,
        timezone: company.timezone,
        approxEmployeeNumber: company.approxEmployeeNumber,
        sectors: company.sectors,
        logoFile: logo,
        settings: {
          [fieldName]: value,
        },
      },
    });

    return this.client.post(`/api/v1/project/${projectId}/company`, data).then(selectApiData);
  }

  close(projectId: number): Promise<ProjectApi.Entry> {
    return this.client.post(`api/v1/project/${projectId}/close`).then(selectApiData);
  }

  delete(projectId: number): Promise<void> {
    return this.client
      .delete<ApiMessage.Deleted>(`/api/v1/project/${projectId}`)
      .then(selectApiNoContent);
  }

  reopen(liveStop: Moment, projectId: number): Promise<ProjectApi.Entry> {
    liveStop = liveStop.clone().utcOffset(0, true);
    const data = jsonToFormData({
      liveStop: liveStop.toISOString(),
    });
    return this.client.post(`/api/v1/project/${projectId}/re-open`, data).then(selectApiData);
  }

  createFirstPage(projectId: number): Promise<ProjectApi.Entry> {
    return this.client
      .post<WithData<ProjectApi.Entry>>(`/api/v1/project/${projectId}/survey`)
      .then(selectApiData);
  }

  publish(projectId: number, generateNewAiInsights: boolean): Promise<void> {
    return this.client.post(`/api/v1/project/${projectId}/publish`, { generateNewAiInsights });
  }
}
