import { selectApiErrors } from 'util/selector/apiSelector';

import { AxiosError } from 'axios';
import { FORM_VALIDATION_ERROR, UNKNOWN_ERROR } from 'exception/EmployeeCodeExpections';
import { Exception } from 'exception/Exception';
import { FormValidationError, SingleApiError } from 'exception/Exception.types';
import { FormValidationException } from 'exception/FormValidationException';

export const createUnknownException = (): Exception =>
  new Exception({
    code: UNKNOWN_ERROR,
    type: UNKNOWN_ERROR,
    message: 'Unknown error',
  });

const isApiError = (
  error: AxiosError<{ errors: SingleApiError[] }>
): error is AxiosError<{ errors: SingleApiError[] }> => {
  return (
    Array.isArray(error.response.data.errors) &&
    error.response.data.errors.length > 0 &&
    error.response.data.errors.every(
      (e) => typeof e.message === 'string' && typeof e.code === 'string'
    )
  );
};

/**
 * Symfony validator return specific error structure
 */
const isFormValidationError = (
  error: AxiosError
): error is AxiosError<{ errors: FormValidationError }> => {
  const errors = selectApiErrors<FormValidationError>(error);

  return (
    errors &&
    typeof errors.message === 'string' &&
    errors.type === FORM_VALIDATION_ERROR &&
    errors.violations.every((e) => typeof e.message === 'string' && typeof e.path === 'string')
  );
};

export const createException = (error: AxiosError): Exception => {
  if (isFormValidationError(error)) {
    const { message, violations } = selectApiErrors<FormValidationError>(error);
    return new FormValidationException(message, violations);
  }

  if (isApiError(error)) {
    const apiErrors = selectApiErrors(error);

    if (apiErrors?.length) {
      const { message, code } = apiErrors[0];
      return createApiException(message, code);
    }
  }

  return createUnknownException();
};

const createApiException = (message: string | undefined, code: string | undefined) => {
  return new Exception({
    type: 'API_ERROR',
    message: message ?? 'Unknown error occurred.',
    code: code ?? 'UNKNOWN_ERROR',
  });
};
