import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentProjectId } from 'reduxStore/project/projectSelector';
import { useFetchGrowthbook } from 'shared/hooks/useFetchGrowthbook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from 'config/growthbook';

type WBGrowthBookProviderProps = {
  children: JSX.Element;
};

export const WBGrowthBookProvider: React.VFC<WBGrowthBookProviderProps> = ({ children }) => {
  const { loading } = useFetchGrowthbook();
  const surveyId = useSelector(selectCurrentProjectId)?.toString();

  useEffect(() => {
    growthbook.setAttributes({ surveyId });
  }, [surveyId]);

  return (
    <>{!loading && <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>}</>
  );
};
