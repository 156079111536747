import { ProjectSettingsApi } from 'api/ProjectSettingsApi';
import { ProjectSettings } from 'model/ProjectSettings';
import moment from 'moment';
import { VARIABLE_CONFIDENTIALITY_THRESHOLD_DISABLED_OPTION } from 'shared/constants';

export class ProjectSettingsMapper {
  serialize(model: ProjectSettings): ProjectSettingsApi.Entry {
    return {
      automaticQuestionNumbering: model.hasAutomaticNumbering(),
      saveAndContinue: model.hasSaveAndContinue(),
      saveAndContinueContent: model.getSaveAndContinueContent(),
      almostFinishedContent: model.getAlmostFinishedContent(),
      thankYouPageContent: model.getThankYouPageContent(),
      closedPageContent: model.getClosedPageContent(),
      surveyCodeQuestionContent: model.getSurveyCodeQuestionContent(),
      minimalAmountOfClosedAnswers: model.getMinimalAmountOfCloseAnswers(),
      minimalAmountOfCommentAnswers: model.getMinimalAmountOfCommentAnswers(),
      threePointScale: model.isThreePointScale(),
      overallScore: model.isOverallScore(),
      respondentProtectionMode: model.getRespondentProtectionMode(),
      inviteSentAt: model.getInviteSentAt()
        ? moment(model.getInviteSentAt()).format('HH:mm:ss')
        : undefined,
      dashboardNotifications: model.getDashboardNotifications(),
      aggregationMonths: model.getAggregationMonths(),
      sentimentAnalysis: model.getSentimentAnalysis(),
      lifecycleSurveys: model.getLifecycleSurveys(),
      ...(model.getIsExecutiveSummaryEnabled() !== undefined
        ? { isExecutiveSummaryEnabled: model.getIsExecutiveSummaryEnabled() }
        : {}),
      ...(model.getIsDepartmentSummaryEnabled() !== undefined
        ? { isDepartmentSummaryEnabled: model.getIsDepartmentSummaryEnabled() }
        : {}),
      lifecycleReminderInterval: model.getLifecycleReminderInterval(),
      showConsentQuestion: model.getShowConsentQuestion(),
      shareFeedbackWithLineManager: model.getShareFeedbackWithLineManager(),
      requireMultiFactorAuthentication: model.getRequireMultiFactorAuthentication(),
      // TODO: simplify when the variable-confidentiality feature flag is removed, the param won't be optional.
      ...(model.getVariableConfidentialityThreshold() !== undefined
        ? {
            variableConfidentialityThreshold:
              model.getVariableConfidentialityThreshold() ===
              VARIABLE_CONFIDENTIALITY_THRESHOLD_DISABLED_OPTION
                ? null
                : model.getVariableConfidentialityThreshold(),
          }
        : {}),
    };
  }

  deserialize(entry: ProjectSettingsApi.Entry): ProjectSettings {
    return new ProjectSettings({
      automaticNumbering: !!entry.automaticQuestionNumbering,
      saveAndContinue: !!entry.saveAndContinue,
      saveAndContinueContent: entry.saveAndContinueContent,
      almostFinishedContent: entry.almostFinishedContent,
      thankYouPageContent: entry.thankYouPageContent,
      closedPageContent: entry.closedPageContent,
      surveyCodeQuestionContent: entry.surveyCodeQuestionContent
        ? entry.surveyCodeQuestionContent
        : null,
      minimalAmountOfClosedAnswers: entry.minimalAmountOfClosedAnswers,
      minimalAmountOfCommentAnswers: entry.minimalAmountOfCommentAnswers,
      threePointScale: entry.threePointScale,
      overallScore: entry.overallScore,
      aggregationMonths: entry.aggregationMonths,
      respondentProtectionMode: entry.respondentProtectionMode,
      inviteSentAt: entry.inviteSentAt ? moment(entry.inviteSentAt, 'HH:mm:ss') : null,
      dashboardNotifications: entry.dashboardNotifications && entry.dashboardNotifications,
      sentimentAnalysis: !!entry.sentimentAnalysis,
      lifecycleSurveys: !!entry.lifecycleSurveys,
      lifecycleReminderInterval: entry.lifecycleReminderInterval,
      showConsentQuestion: !!entry.showConsentQuestion,
      shareFeedbackWithLineManager: !!entry.shareFeedbackWithLineManager,
      requireMultiFactorAuthentication: !!entry.requireMultiFactorAuthentication,
      // TODO: simplify when the variable-confidentiality feature flag is removed, the param won't be optional.
      ...(entry.variableConfidentialityThreshold !== undefined
        ? {
            variableConfidentialityThreshold:
              entry.variableConfidentialityThreshold ??
              VARIABLE_CONFIDENTIALITY_THRESHOLD_DISABLED_OPTION,
          }
        : {}),
      ...(entry.isExecutiveSummaryEnabled !== undefined
        ? { isExecutiveSummaryEnabled: entry.isExecutiveSummaryEnabled }
        : {}),
      ...(entry.isDepartmentSummaryEnabled !== undefined
        ? { isDepartmentSummaryEnabled: entry.isDepartmentSummaryEnabled }
        : {}),
    });
  }
}
