import { jsonToFormData } from 'util/jsonToFormData';
import { selectApiData } from 'util/selector/apiSelector';
import { selectResponseData } from 'util/selector/axiosSelector';

import { WithData } from 'api/interfaces';
import { ProjectApi } from 'api/ProjectApi';
import { AxiosInstance } from 'axios';

export namespace CompanyApi {
  export type Entry = CompanyApi.PostEntry & {
    id: number;
    logo: string;
  };

  export type PostEntry = {
    circleId: string;
    name: string;
    brandColor: string;
    timezone: string;
    settings: {
      isDashboardLanguagesEnabled?: boolean;
      shouldTranslateComments?: boolean;
      enabledLocales: string[];
      enabledDashboardLocales: string[];
      ssoEnabled: boolean;
      multiLanguageEnabled: boolean;
      customKioskCodesEnabled: boolean;
      aiSummariesEnabled?: boolean;
      hasAiSummariesEnabled?: boolean;
      hasRespondentExternalEventApiEnabled?: boolean;
      hasDemographyExternalEventApiEnabled?: boolean;
      hasDepartmentExternalEventApiEnabled?: boolean;
      hasAvailableSurveysLinkEnabled?: boolean;
      hasLifecycleSubscriptionExternalEventApiEnabled?: boolean;
    };
    approxEmployeeNumber: number;
    sectors: number[];
    plan?: number; // TODO investigate if this can be removed and only present in clientApi
  };

  export type EnabledLocales = {
    enabledLocales: string[];
  };

  export type GetListResponse = Entry[];
}

export class CompanyApi {
  constructor(private client: AxiosInstance) {}

  create(client: CompanyApi.PostEntry, logo?: File): Promise<CompanyApi.Entry> {
    const newClient = { ...client, logoFile: logo };
    return this.client
      .post<WithData<CompanyApi.Entry>>(`/api/v1/company`, jsonToFormData(newClient))
      .then(selectApiData);
  }

  getList(query: string): Promise<CompanyApi.GetListResponse> {
    return this.client
      .get(`/api/v1/company${query}`)
      .then((response) => selectResponseData(response, '_embedded.items', []));
  }

  getProjectList(query: string): Promise<ProjectApi.PaginatedEntry> {
    return this.client
      .get(`/api/v1/project${query}`)
      .then((response) => selectResponseData(response) || []);
  }

  updateEnabledLanguages(id: number, languages: string[]): Promise<CompanyApi.EnabledLocales> {
    return this.client.put(`/api/v1/company/${id}/enabled-locales`, {
      enabledLocales: languages,
    });
  }

  updateEnabledDashboardLanguages(
    id: number,
    languages: string[],
    isDashboardLanguagesEnabled: boolean,
    shouldTranslateComments: boolean
  ): Promise<CompanyApi.EnabledLocales> {
    return this.client.put(`/api/v1/company/${id}/enabled-dashboard-locales`, {
      enabledDashboardLocales: languages,
      isDashboardLanguagesEnabled,
      shouldTranslateComments,
    });
  }
}
