import { RGBColor } from 'react-color';

import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace Company {
  export interface Shape {
    id?: number;
    name: string;
    circleId: string;
    brandColor?: RGBColor;
    timezone: string;
    settings: {
      isDashboardLanguagesEnabled?: boolean;
      shouldTranslateComments?: boolean;
      enabledLocales: LangCodeEnum[];
      enabledDashboardLocales: string[];
      ssoEnabled: boolean;
      multiLanguageEnabled: boolean;
      customKioskCodesEnabled: boolean;
      aiSummariesEnabled?: boolean;
      // TODO: remove all hasAiSummariesEnabled usages when project endpoint returns aiSummariesEnabled instead of hasAiSummariesEnabled https://buzzing.atlassian.net/browse/AIS-189
      hasAiSummariesEnabled?: boolean;
      hasRespondentExternalEventApiEnabled?: boolean;
      hasDemographyExternalEventApiEnabled?: boolean;
      hasDepartmentExternalEventApiEnabled?: boolean;
      hasAvailableSurveysLinkEnabled?: boolean;
      hasLifecycleSubscriptionExternalEventApiEnabled?: boolean;
    };
    approxEmployeeNumber?: number;
    sectors?: List<number>;
    logo?: string;
    plan?: number;
  }
}

export class Company extends SimpleModel<Company.Shape> {
  getId(): number {
    return this.get('id');
  }

  getCircleId(): string {
    return this.get('circleId');
  }

  getName(): string {
    return this.get('name');
  }

  getLogo(): string {
    return this.get('logo');
  }

  getBrandColor(): RGBColor {
    return this.get('brandColor');
  }

  getTimezone(): string {
    return this.get('timezone');
  }

  getSSOEnabled(): boolean {
    return this.get('settings').ssoEnabled;
  }

  getEnabledLocales(): LangCodeEnum[] {
    return this.get('settings').enabledLocales;
  }

  getEnabledDashboardLocales(): string[] {
    return this.get('settings').enabledDashboardLocales;
  }

  getIsDashboardLanguagesEnabled(): boolean {
    return this.get('settings').isDashboardLanguagesEnabled;
  }

  getShouldTranslateComments(): boolean {
    return this.get('settings').shouldTranslateComments;
  }

  getSectors(): List<number> {
    return this.get('sectors');
  }

  getApproxEmployeeNumber(): number {
    return this.get('approxEmployeeNumber');
  }

  getPlan(): number {
    return this.get('plan');
  }

  getMultiLanguageEnabled(): boolean {
    return this.get('settings').multiLanguageEnabled;
  }

  getCustomKioskCodesEnabled(): boolean {
    return this.get('settings').customKioskCodesEnabled;
  }

  getAiSummariesEnabled(): boolean {
    return this.get('settings').aiSummariesEnabled ?? this.get('settings').hasAiSummariesEnabled;
  }

  getHasRespondentExternalEventApiEnabled(): boolean {
    return (
      this.get('settings').hasRespondentExternalEventApiEnabled ??
      this.get('settings').hasRespondentExternalEventApiEnabled
    );
  }

  getHasDemographyExternalEventApiEnabled(): boolean {
    return (
      this.get('settings').hasDemographyExternalEventApiEnabled ??
      this.get('settings').hasDemographyExternalEventApiEnabled
    );
  }

  getHasDepartmentExternalEventApiEnabled(): boolean {
    return (
      this.get('settings').hasDepartmentExternalEventApiEnabled ??
      this.get('settings').hasDepartmentExternalEventApiEnabled
    );
  }

  getHasAvailableSurveysLinkEnabled(): boolean {
    return (
      this.get('settings').hasAvailableSurveysLinkEnabled ??
      this.get('settings').hasAvailableSurveysLinkEnabled
    );
  }

  getHasLifecycleSubscriptionExternalEventApiEnabled(): boolean {
    return (
      this.get('settings').hasLifecycleSubscriptionExternalEventApiEnabled ??
      this.get('settings').hasLifecycleSubscriptionExternalEventApiEnabled
    );
  }
}
