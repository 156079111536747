import { CompanyApi } from 'api/CompanyApi';
import { List } from 'immutable';
import { Company } from 'model/Company';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import tinycolor from 'tinycolor2';

export class CompanyMapper {
  serialize(model: Company): CompanyApi.Entry {
    return {
      id: model.getId(),
      name: model.getName(),
      logo: model.getLogo(),
      brandColor: tinycolor(model.getBrandColor()).toHexString(),
      circleId: model.getCircleId(),
      timezone: model.getTimezone(),
      settings: {
        isDashboardLanguagesEnabled: model.getIsDashboardLanguagesEnabled(),
        shouldTranslateComments: model.getShouldTranslateComments(),
        enabledLocales: model.getEnabledLocales(),
        enabledDashboardLocales: model.getEnabledDashboardLocales(),
        ssoEnabled: model.getSSOEnabled(),
        multiLanguageEnabled: model.getMultiLanguageEnabled(),
        customKioskCodesEnabled: model.getCustomKioskCodesEnabled(),
        aiSummariesEnabled: model.getAiSummariesEnabled(),
        hasRespondentExternalEventApiEnabled: model.getHasRespondentExternalEventApiEnabled(),
        hasDemographyExternalEventApiEnabled: model.getHasDemographyExternalEventApiEnabled(),
        hasDepartmentExternalEventApiEnabled: model.getHasDepartmentExternalEventApiEnabled(),
        hasAvailableSurveysLinkEnabled: model.getHasAvailableSurveysLinkEnabled(),
        hasLifecycleSubscriptionExternalEventApiEnabled: model.getHasLifecycleSubscriptionExternalEventApiEnabled(),
      },
      sectors: model.getSectors().toArray(),
      approxEmployeeNumber: model.getApproxEmployeeNumber(),
      plan: model.getPlan(),
    };
  }

  deserialize(entry: CompanyApi.Entry): Company {
    return new Company({
      id: entry.id,
      circleId: entry.circleId,
      name: entry.name,
      logo: entry.logo,
      sectors: List(entry.sectors),
      approxEmployeeNumber: entry.approxEmployeeNumber,
      brandColor: tinycolor(entry.brandColor).toRgb(),
      timezone: entry.timezone,
      settings: {
        isDashboardLanguagesEnabled: entry?.settings?.isDashboardLanguagesEnabled,
        shouldTranslateComments: entry?.settings?.shouldTranslateComments,
        enabledLocales: entry?.settings?.enabledLocales as LangCodeEnum[],
        enabledDashboardLocales: entry?.settings?.enabledDashboardLocales as string[],
        ssoEnabled: entry?.settings?.ssoEnabled,
        multiLanguageEnabled: entry?.settings?.multiLanguageEnabled,
        customKioskCodesEnabled: entry?.settings?.customKioskCodesEnabled,
        aiSummariesEnabled: entry?.settings?.aiSummariesEnabled,
        hasAiSummariesEnabled: entry?.settings?.hasAiSummariesEnabled,
        hasRespondentExternalEventApiEnabled: entry?.settings?.hasRespondentExternalEventApiEnabled,
        hasDemographyExternalEventApiEnabled: entry?.settings?.hasDemographyExternalEventApiEnabled,
        hasDepartmentExternalEventApiEnabled: entry?.settings?.hasDepartmentExternalEventApiEnabled,
        hasAvailableSurveysLinkEnabled: entry?.settings?.hasAvailableSurveysLinkEnabled,
        hasLifecycleSubscriptionExternalEventApiEnabled:
          entry?.settings?.hasLifecycleSubscriptionExternalEventApiEnabled,
      },
      plan: entry.plan,
    });
  }
}
