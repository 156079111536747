import { createEmployeePaginationAndSortQuery } from 'util/createEmployeePaginationAndSortQuery';
import { createEmployeeUploadQuery } from 'util/createEmployeeUploadQuery';

import { EmployeeApi } from 'api/EmployeeApi';
import { List } from 'immutable';
import { EmployeeMapper } from 'mapper/EmployeeMapper';
import { EmployeeStatsMapper } from 'mapper/EmployeeStatsMapper';
import { PaginationMapper } from 'mapper/PaginationMapper';
import { EmployeeStats } from 'model/EmploeeStats';
import { Employee } from 'model/Employee';
import { EmployeeUploadType } from 'model/EmployeeUploadType';
import { Pagination } from 'model/Pagination';

export class EmployeeRepository {
  constructor(
    private employeeApi: EmployeeApi,
    private employeeMapper: EmployeeMapper,
    private paginationMapper: PaginationMapper,
    private employeeStatsMapper: EmployeeStatsMapper
  ) {}

  list({
    projectId,
    page,
    limit,
    searchText,
    department,
    active,
    createdAtSince,
    sort,
  }: {
    projectId: number;
    page: number;
    limit: number;
    searchText?: string;
    department?: number;
    active?: EmployeeApi.FilterActiveParam;
    createdAtSince?: string;
    sort?: EmployeeApi.SortParam;
  }): Promise<{ list: List<Employee>; pagination: Pagination }> {
    const query = createEmployeePaginationAndSortQuery(
      page,
      limit,
      searchText,
      department,
      active,
      sort,
      createdAtSince
    );

    return this.employeeApi.list(projectId, query).then((result) => ({
      list: List(result._embedded.items.map((entry) => this.employeeMapper.deserialize(entry))),
      pagination: this.paginationMapper.deserialize(result),
    }));
  }

  process(projectId: number, file: File): Promise<void> {
    return this.employeeApi.process(projectId, file);
  }

  commit(projectId: number, jobId: number, type: EmployeeUploadType): Promise<void> {
    const query = createEmployeeUploadQuery(type);

    return this.employeeApi.commit(projectId, jobId, query);
  }

  add(projectId: number, employee: Employee): Promise<void> {
    return this.employeeApi.add(projectId, this.employeeMapper.serialize(employee));
  }

  edit(projectId: number, employeeId: number, employee: Employee): Promise<Employee> {
    const serializedEmployee = this.employeeMapper.serialize(employee);

    return this.employeeApi
      .edit(projectId, employeeId, serializedEmployee)
      .then((result) => this.employeeMapper.deserialize(result));
  }

  delete(employeeId: number): Promise<void> {
    return this.employeeApi.delete(employeeId);
  }

  updateEmail(employeeId: number, email: string): Promise<void> {
    return this.employeeApi.updateEmail(employeeId, email);
  }

  stats(projectId: number): Promise<EmployeeStats> {
    return this.employeeApi
      .stats(projectId)
      .then((result) => this.employeeStatsMapper.deserialize(result));
  }
}
