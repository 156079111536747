import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import posthog from 'posthog-js';
import { selectUserId, selectUserRoles } from 'reduxStore/auth/selectors';
import { selectCurrentCompanyName } from 'reduxStore/project/projectSelector';

export const useIdentifyPostHog = () => {
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectUserRoles);
  const isPosthogEnabled = useFeatureIsOn('posthog-core');
  const companyName = useSelector(selectCurrentCompanyName);

  useEffect(() => {
    if (!isPosthogEnabled || !userId) {
      return;
    }

    posthog.identify(userId, { roles: roles, clients: companyName });
  }, [userId, roles, isPosthogEnabled, companyName]);
};
