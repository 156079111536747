import { matchPath } from 'react-router-dom';

import { getProject } from 'util/getProject';
import { isArrayEmpty } from 'util/isArrayEmpty';
import { mapLifecycleTypeToLifecycleLabel } from 'util/mapLifecycleTypeToLifecycleLabel';
import { selectSurveyUiUrl } from 'util/selector/apiSelector';
import { createResourceContentSelector } from 'util/selector/resourceSelector';
import { selectHasMatch, selectLocationPath, selectMatch } from 'util/selector/routerSelector';

import { AdminRoute } from 'app/route/admin';
import { ClientRoute } from 'app/route/client';
import { ExternalRoute } from 'app/route/external';
import { List } from 'immutable';
import memoize from 'lodash/memoize';
import { ClientPlanType } from 'model/ClientPlanType';
import { NavigationNode } from 'model/NavigationNode';
import { PageItemKind } from 'model/PageItemKind';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { ProjectStatus } from 'model/ProjectStatus';
import { PulseFrequency } from 'model/PulseFrequency';
import { PulseTemplate } from 'model/PulseTemplate';
import { Question } from 'model/Question';
import { QuestionOpinion } from 'model/QuestionOpinion';
import { Resource } from 'model/Resource';
import { formValueSelector } from 'redux-form';
import { AdminState } from 'reduxStore/appStore';
import { selectQuestions } from 'reduxStore/page/selectors';
import { ProjectState } from 'reduxStore/project/initialState';
import {
  selectIsProjectTemplate,
  selectProjectTemplateList,
} from 'reduxStore/projectTemplate/selectors';
import { selectWebLinkDistributionResource } from 'reduxStore/webLink/selectors';
import { AdminFormNames } from 'register/AdminFormNames';
import { FetchSubProjectsQuery } from 'repository/PulseProjectRepository';
import { createSelector, Selector } from 'reselect';
import { compilePath } from 'router/compilePath';
import { SortDirection } from 'shared/constants';

export function selectProjectDomain(state: AdminState): ProjectState.Domain {
  return state && state.project;
}

export const selectProjectListResource = createSelector(
  selectProjectDomain,
  (domain) => (domain && domain.listResource) || Resource.void<ProjectState.ProjectList>()
);

export const selectProjectListPagination = createSelector(
  selectProjectDomain,
  (domain) => domain && domain.listPagination
);

export const selectCurrentProjectResource = createSelector(
  selectProjectDomain,
  (domain) => domain?.currentResource || Resource.void<Project | PulseTemplate>()
);

export const selectCurrentProjectContent = createResourceContentSelector(
  selectCurrentProjectResource
);

export const selectCurrentPulseTemplate = createSelector(
  selectCurrentProjectContent,
  (pulseTemplate) => {
    if (pulseTemplate instanceof Project) {
      console.error('Trying to get pulse template data on normal project page!');
      return null;
    }

    return pulseTemplate;
  }
);

export const selectCurrentProject = createSelector(
  selectCurrentProjectContent,
  (projectOrTemplate) => {
    return getProject(projectOrTemplate);
  }
);

export const selectIsCurrentProjectClosed = createSelector(
  selectCurrentProject,
  (project) => project.getStatus() === ProjectStatus.Closed
);

export const selectProjectFrequency = createSelector(selectCurrentProject, (project) =>
  project.getPulseFrequencyId()
);

export const selectChildProjectsResource = createSelector(selectCurrentPulseTemplate, (project) => {
  if (project instanceof Project) {
    throw new Error('Trying to get pulse template child projects on normal project page!');
  }

  if (!project) {
    return Resource.void<List<Project>>();
  }

  return Resource.resolve<List<Project>>(project.getChildProjects());
});

export const selectCurrentCompany = createSelector(selectCurrentProject, (project) =>
  project?.getCompany()
);

export const selectCompanyCircleId = createSelector(selectCurrentCompany, (company) =>
  company?.getCircleId()
);

export const selectCurrentCompanyPlan = createSelector(selectCurrentCompany, (company) =>
  company?.getPlan()
);

export const selectIsMultiLanguageEnabled = createSelector(selectCurrentCompany, (company) =>
  company?.getMultiLanguageEnabled()
);

export const selectIsCustomKioskCodesEnabled = createSelector(selectCurrentCompany, (company) =>
  company?.getCustomKioskCodesEnabled()
);

export const selectAiSummariesEnabled = createSelector(selectCurrentCompany, (company) =>
  company?.getAiSummariesEnabled()
);

export const selectIsRespondentsApiEnabled = createSelector(selectCurrentCompany, (company) =>
  company?.getHasRespondentExternalEventApiEnabled()
);

export const selectHasDemographyExternalEventApiEnabled = createSelector(
  selectCurrentCompany,
  (company) => company?.getHasDemographyExternalEventApiEnabled()
);

export const selectHasDepartmentExternalEventApiEnabled = createSelector(
  selectCurrentCompany,
  (company) => company?.getHasDepartmentExternalEventApiEnabled()
);

export const selectHasAvailableSurveysLinkEnabled = createSelector(
  selectCurrentCompany,
  (company) => company?.getHasAvailableSurveysLinkEnabled()
);

export const selectHasLifecycleSubscriptionExternalEventApiEnabled = createSelector(
  selectCurrentCompany,
  (company) => company?.getHasLifecycleSubscriptionExternalEventApiEnabled()
);

export const selectEnabledLocales = createSelector(selectCurrentCompany, (company) =>
  company?.getEnabledLocales()
);

export const selectEnabledDashboardLocales = createSelector(selectCurrentCompany, (company) =>
  company?.getEnabledDashboardLocales()
);

export const selectIsDashboardLanguagesEnabled = createSelector(selectCurrentCompany, (company) =>
  company?.getIsDashboardLanguagesEnabled()
);

export const selectShouldTranslateComments = createSelector(selectCurrentCompany, (company) =>
  company?.getShouldTranslateComments()
);

export const selectIsEssentialPlan = createSelector(
  selectCurrentCompanyPlan,
  (plan) => plan && plan === ClientPlanType.Essential
);

export const selectIsProfessionalPlan = createSelector(
  selectCurrentCompanyPlan,
  (plan) => plan && plan === ClientPlanType.Professional
);

export const selectIsEnterprisePlan = createSelector(
  selectCurrentCompanyPlan,
  (plan) => plan && plan === ClientPlanType.Enterprise
);

export const selectIsLegacyPlan = createSelector(
  selectCurrentCompanyPlan,
  (plan) => plan && plan === ClientPlanType.Legacy
);

export const selectHasProfessionalOrEnterprisePermission = createSelector(
  selectIsProfessionalPlan,
  selectIsEnterprisePlan,
  selectIsLegacyPlan,
  (isProfessionalPlan, isEnterprisePlan, isLegacy) =>
    isEnterprisePlan || isProfessionalPlan || isLegacy
);

export const selectCurrentLiveStop = createSelector(
  selectCurrentProject,
  (project) => project && project.getLiveStop()
);

export const selectCurrentCompanyId = createSelector(selectCurrentCompany, (company) =>
  company ? company.getId() : null
);

export const selectCurrentCompanyName = createSelector(selectCurrentCompany, (company) =>
  company ? company.getName() : null
);

export const selectCurrentKind = createSelector(selectCurrentProject, (project) =>
  project ? project.getKind() : null
);

export const createProjectTypeFormSelector = formValueSelector(AdminFormNames.CreateProjectType);

export const selectProjectType: Selector<AdminState, boolean> = (state) =>
  createProjectTypeFormSelector(state, 'type');

export const selectCurrentProjectId = createSelector(
  selectMatch(
    AdminRoute.PROJECT_PATTERN,
    AdminRoute.PROJECT_TEMPLATE_PATTERN,
    ClientRoute.PROJECT_PATTERN
  ),
  (match) => match && parseInt(match.params.id, 10)
);

export const selectCurrentProjectOnlyId = createSelector(
  selectMatch(AdminRoute.PROJECT_PATTERN, ClientRoute.PROJECT_PATTERN),
  (match) => match && parseInt(match.params.id, 10)
);

export const selectIsProjectTypeSelected = createSelector(selectProjectType, (projectType) =>
  Boolean(projectType)
);

export const selectIsProjectPublished = createSelector(
  selectCurrentProject,
  (project) => project && project.isPublished()
);

export const selectProjectPreviewUrlResource = createSelector(
  selectSurveyUiUrl,
  selectWebLinkDistributionResource,
  (surveyUiUrl, webLinkDistributionResource) =>
    Resource.combine(webLinkDistributionResource).setContent(
      webLinkDistributionResource.isResolved()
        ? `${surveyUiUrl}/${webLinkDistributionResource.getContent().getId()}/preview`
        : null
    )
);

export const selectIsProjectKindCAS = createSelector(
  selectCurrentKind,
  (kind) => kind === ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE
);

export const selectIsSurveyTypeEngagementScore = createSelector(
  selectCurrentKind,
  (kind) => kind === ProjectKind.PROJECT_ENGAGEMENT_SCORE
);

export const selectIsSurveyTypeOverallScore = createSelector(
  selectCurrentKind,
  (kind) => kind === ProjectKind.PROJECT_OVERALL_SCORE
);

// it will search in question list for checkbox/radio question
export const selectHasInsightQuestion = createSelector(
  selectQuestions,
  (questions: List<Question>) =>
    questions &&
    !!questions.find((question: Question) =>
      [PageItemKind.QuestionRadioButton, PageItemKind.QuestionCheckbox].includes(question.getKind())
    )
);

export const selectHasProjectCASQuestion = createSelector(
  selectIsProjectKindCAS,
  selectQuestions,
  (isCASSurvey: boolean, questions: List<Question>) =>
    isCASSurvey &&
    questions &&
    !!questions.find((question: Question) => question.getKind() === PageItemKind.QuestionCAS)
);

export const selectHasProjectOpinionQuestion = createSelector(
  selectIsSurveyTypeEngagementScore,
  selectQuestions,
  (isEngagementScore: boolean, questions: List<Question>) =>
    isEngagementScore &&
    questions &&
    !!questions
      .filter((question) => question.getKind() === PageItemKind.QuestionOpinion)
      .find(
        (opinionQuestion: QuestionOpinion) =>
          opinionQuestion.getCategory().getName() === 'Employee Engagement'
      )
);

export const selectIsCurrentProjectFixableOnly = createSelector(
  selectCurrentProject,
  (project) => project && project.isFixableOnly()
);

export const selectFilteredTemplateList = createSelector(
  selectProjectTemplateList,
  selectCurrentKind,
  (templateList, currentProjectKind) =>
    templateList &&
    templateList.filter((template) => template.getKind() === currentProjectKind).toList()
);

export const selectIsPulseTemplate = createSelector(
  selectCurrentKind,
  (kind) => kind && kind === ProjectKind.PULSE_TEMPLATE
);

export const selectPulseProjects = createSelector(
  selectCurrentPulseTemplate,
  (project) => project && project.getPulseProjects()
);

export const selectPulseLifecycleProjects = createSelector(
  selectCurrentPulseTemplate,
  (project) => project && project.getPulseLifecycleProjects()
);

export const selectDemandPolls = createSelector(
  selectCurrentPulseTemplate,
  (project) => project && project.getDemandPolls()
);

export const selectFixablePulseProjects = createSelector(
  selectPulseProjects,
  (projects) => projects && projects.filter((project) => project.isFixableOnly()).toList()
);

export const selectHasPulseProjects = createSelector(
  selectPulseProjects,
  (pulseProjects) => pulseProjects && !isArrayEmpty(pulseProjects)
);

export const selectSubProjectCurrentId = createSelector(
  selectMatch(ClientRoute.Project.Survey.DEVELOP_PATTERN),
  (match) => match && parseInt(match.params.survey_id, 10)
);

export const selectCurrentPulseProject = createSelector(
  selectPulseProjects,
  selectSubProjectCurrentId,
  (projects, projectId) => projects && projects.find((item) => item.getId() === projectId)
);

export const selectDemandAndPulseAndLifecycle = createSelector(
  selectPulseProjects,
  selectDemandPolls,
  selectPulseLifecycleProjects,
  (pulses, demands, lifecycles) =>
    List.of(
      ...(pulses ? pulses.toArray() : []),
      ...(demands ? demands.toArray() : []),
      ...(lifecycles ? lifecycles.toArray() : [])
    )
);

export const selectCurrentSubProject = createSelector(
  selectDemandAndPulseAndLifecycle,
  selectSubProjectCurrentId,
  (projects, currentProjectId) =>
    projects && projects.find((item) => item.getId() === currentProjectId)
);

export const selectLifecycleType = createSelector(
  selectCurrentSubProject,
  (project) => project && project.getLifecycleType()
);

export const selectCurrentSubProjectId = createSelector(selectCurrentSubProject, (project) =>
  project?.getId()
);

export const selectCurrentSubProjectKind = createSelector(
  selectCurrentSubProject,
  (project) => project && project.getKind()
);

export const selectDemandPollsToCopy = createSelector(
  selectCurrentPulseTemplate,
  selectSubProjectCurrentId,
  (project, demandPollId) => {
    return project
      ? project
          .getDemandPolls()
          .filter((poll) => poll.getId() !== demandPollId)
          .sort(Project.sortByLiveStop)
          .toArray()
      : ([] as Project[]);
  }
);

export const selectIsDemandPoll = createSelector(
  selectCurrentSubProject,
  (project) => project && project.isDemandPoll()
);

export const selectIsLifecycleProject = createSelector(
  selectCurrentSubProject,
  (project) => project && project.isLifecycleProject()
);

export const selectCurrentPulseProjectResponseRate = createSelector(
  selectCurrentSubProject,
  (project) => project && project.getResponseRate()
);

export const selectNextPulseProject = createSelector(
  selectPulseProjects,
  selectSubProjectCurrentId,
  (pulseProjects, projectId) => {
    let currentIndex;

    if (!isArrayEmpty(pulseProjects) && projectId) {
      currentIndex = pulseProjects.findIndex((project) => project.getId() === projectId);
    }

    return pulseProjects.get(currentIndex + 1) ? pulseProjects.get(currentIndex + 1) : undefined;
  }
);

export const selectPrevPulseProject = createSelector(
  selectPulseProjects,
  selectSubProjectCurrentId,
  (pulseProjects, projectId) => {
    let currentIndex;

    if (!isArrayEmpty(pulseProjects) && projectId) {
      currentIndex = pulseProjects.findIndex((project) => project.getId() === projectId);
    }

    return currentIndex !== 0 ? pulseProjects.get(currentIndex - 1) : undefined;
  }
);

export const selectHasPulseProject = createSelector(
  selectPulseProjects,
  (projects) => projects && !isArrayEmpty(projects)
);

export const selectIsProjectEditable = createSelector(
  selectCurrentProject,
  selectIsProjectTemplate,
  selectHasMatch(ClientRoute.Project.Survey.DEVELOP_PATTERN),
  selectCurrentSubProject,
  selectIsLifecycleProject,
  (project, isTemplate, isPulseTemplate, currentSubProject, isLifecycleProject) => {
    return (
      isLifecycleProject ||
      isTemplate ||
      (isPulseTemplate
        ? currentSubProject && currentSubProject.isEditable()
        : project && project.isEditable())
    );
  }
);

export const selectIsCurrentSubProjectFixableOnly = createSelector(
  selectCurrentSubProject,
  (project) => project && project.isFixableOnly()
);

export const selectIsCurrentSubProjectClosed = createSelector(
  selectCurrentSubProject,
  (project) => project && project.isClosed()
);

export const selectClosedPulseProjects = createSelector(
  selectPulseProjects,
  selectHasPulseProject,
  (projects, hasPulseProject) =>
    projects && hasPulseProject
      ? List(projects.toArray().filter((item) => item.getStatus() === ProjectStatus.Closed))
      : List<Project>()
);

export const selectIsAnyPulseProjectClosed = createSelector(
  selectClosedPulseProjects,
  (projectsList) => !isArrayEmpty(projectsList)
);

export const selectLastClosePulseProject = createSelector(
  selectClosedPulseProjects,
  (projects) => projects && projects.last()
);

export const selectInDevelopmentPulseProjects = createSelector(
  selectPulseProjects,
  selectHasPulseProject,
  (projects, hasPulseProject) =>
    projects && hasPulseProject
      ? List(projects.toArray().filter((item) => item.getStatus() === ProjectStatus.InDevelopment))
      : List<Project>()
);

export const selectLivePulseProjects = createSelector(
  selectPulseProjects,
  selectHasPulseProject,
  (projects, hasPulseProject) =>
    projects && hasPulseProject
      ? projects.filter((item) => item.getStatus() === ProjectStatus.Live).toList()
      : List<Project>()
);

export const selectLiveDemandProjects = createSelector(selectDemandPolls, (projects) =>
  projects
    ? projects.filter((item) => item.getStatus() === ProjectStatus.Live).toList()
    : List<Project>()
);

export const selectHasLivePulseOrDemandProjects = createSelector(
  selectLivePulseProjects,
  selectLiveDemandProjects,
  (pulses, demands) => pulses.size > 0 || demands.size > 0
);

export const selectIsVisibleQuestionLibraryAddButton = createSelector(
  selectProjectFrequency,
  selectInDevelopmentPulseProjects,
  selectLivePulseProjects,
  (frequency, pulsesInDevelopment, livePulses) => {
    const isAnnualFrequency = frequency === PulseFrequency.annually;
    const arePulsesInDevelopment = pulsesInDevelopment.toArray().length > 0;
    const areLivePulses = livePulses.toArray().length > 0;
    return !(isAnnualFrequency && areLivePulses && !arePulsesInDevelopment);
  }
);

export const selectInDevPulseProjectsWithoutCurrent = memoize(
  (id: number): Selector<AdminState, List<Project>> =>
    createSelector(
      selectInDevelopmentPulseProjects,
      (list) => list && list.filter((item) => item.getId() !== id).toList()
    )
);

export const selectLibraryQuestions = createSelector(
  selectCurrentProject,
  (project) => project && project.getQuestionLibrary()
);

export const selectLibraryQuestionsGroupedByCategory = createSelector(
  selectLibraryQuestions,
  (questions) =>
    questions &&
    questions
      .groupBy((item) => item.getCategory().getId())
      .toArray()
      .reduce((acc, cur) => acc.concat(cur).toList(), List())
);

export const selectFirstPulseProjectInDevelopment = createSelector(
  selectInDevelopmentPulseProjects,
  (project) => project && project.first()
);

export const selectIsPulseFromPast = createSelector(
  selectCurrentPulseProject,
  (project) => project && project.isCreatedInPast()
);

const locationQueries: { [key: string]: FetchSubProjectsQuery } = {
  [ClientRoute.Project.Survey.LIVE_AND_SCHEDULED]: {
    kind: ProjectKind.PULSE_PROJECT,
    status: [ProjectStatus.Live, ProjectStatus.InDevelopment],
    order: SortDirection.ASC,
    page: 1,
  },
  [ClientRoute.Project.Survey.ARCHIVED]: {
    kind: ProjectKind.PULSE_PROJECT,
    status: [ProjectStatus.Closed],
    order: SortDirection.DESC,
    page: 1,
  },
  [ClientRoute.Project.DemandPoll.LIVE_AND_SCHEDULED]: {
    kind: ProjectKind.PULSE_ON_DEMAND_PROJECT,
    status: [ProjectStatus.Live, ProjectStatus.InDevelopment],
    order: SortDirection.ASC,
    page: 1,
  },
  [ClientRoute.Project.DemandPoll.ARCHIVED]: {
    kind: ProjectKind.PULSE_ON_DEMAND_PROJECT,
    status: [ProjectStatus.Closed],
    order: SortDirection.DESC,
    page: 1,
  },
};

export const selectSubProjectsPageQuery = createSelector(selectLocationPath, (currentLocation) => {
  const query = Object.keys(locationQueries).find((location) => {
    return !!matchPath(currentLocation, { path: location, exact: false });
  });

  return query ? locationQueries[query] : ({} as FetchSubProjectsQuery);
});

export const selectReportUILinks = createSelector(
  selectCurrentPulseTemplate,
  (project): NavigationNode[] => {
    if (!project || !project.getProject().isPublished()) {
      return [];
    }

    const reportUILinks: NavigationNode[] = [];

    let id = Project.lastPublishedProjectId(project.getPulseProjects());
    if (typeof id === 'number') {
      reportUILinks.push({
        href: compilePath(ExternalRoute.ProjectReportSummary, { id }),
        label: 'Engage (Pulse Surveys)',
      });
    }

    id = Project.lastPublishedProjectId(project.getDemandPolls());
    if (typeof id === 'number') {
      reportUILinks.push({
        href: compilePath(ExternalRoute.ProjectReportSummary, { id }),
        label: 'On Demand Polls',
      });
    }
    const lifecycleProjects = project.getPulseLifecycleProjects();

    lifecycleProjects.forEach((lifecycleProject) => {
      if (lifecycleProject && lifecycleProject.isPublished()) {
        reportUILinks.push({
          href: compilePath(ExternalRoute.ProjectReportSummary, { id: lifecycleProject.getId() }),
          label: mapLifecycleTypeToLifecycleLabel(lifecycleProject.getLifecycleType()),
        });
      }
    });
    return reportUILinks;
  }
);

export const selectAnyPulseLifecycleProjectHasConsentQuestionOn = createSelector(
  selectPulseLifecycleProjects,
  (pulseLifecycleProjects) =>
    pulseLifecycleProjects.some((project) => project.getSettings().getShowConsentQuestion())
);
