import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export type DashboardLanguage = {
  code: string;
  title: string;
};

type languageConfig = {
  baseLanguage: LangCodeEnum;
  languages: LangCodeEnum[];
  dashboardLanguages: DashboardLanguage[];
};

export const languageConfig: languageConfig = {
  baseLanguage: LangCodeEnum.ENGLISH_BRITISH,
  languages: [
    LangCodeEnum.AFRIKAANS,
    LangCodeEnum.ARABIC_UAE,
    LangCodeEnum.BELGIAN,
    LangCodeEnum.BENGALI,
    LangCodeEnum.BOSNIAN,
    LangCodeEnum.BULGARIAN,
    LangCodeEnum.BURMESE,
    LangCodeEnum.CHINESE_SIMPLIFIED,
    LangCodeEnum.CHINESE_TRADITIONAL,
    LangCodeEnum.CROATIAN,
    LangCodeEnum.CZECH,
    LangCodeEnum.DANISH,
    LangCodeEnum.DUTCH,
    LangCodeEnum.ENGLISH_AMERICAN,
    LangCodeEnum.ENGLISH_BRITISH,
    LangCodeEnum.FILIPINO,
    LangCodeEnum.FINNISH,
    LangCodeEnum.FRENCH,
    LangCodeEnum.GERMAN,
    LangCodeEnum.GUJARATI,
    LangCodeEnum.HINDI,
    LangCodeEnum.HUNGARIAN,
    LangCodeEnum.INDONESIAN,
    LangCodeEnum.IRISH,
    LangCodeEnum.ITALIAN,
    LangCodeEnum.JAPANESE,
    LangCodeEnum.KOREAN,
    LangCodeEnum.LATVIAN,
    LangCodeEnum.LITHUANIAN,
    LangCodeEnum.MALAGASY,
    LangCodeEnum.NORWEGIAN,
    LangCodeEnum.PERSIAN,
    LangCodeEnum.POLISH,
    LangCodeEnum.PORTUGUESE,
    LangCodeEnum.PORTUGUESE_BRAZILIAN,
    LangCodeEnum.PUNJABI_INDIAN,
    LangCodeEnum.ROMANIAN,
    LangCodeEnum.RUSSIAN,
    LangCodeEnum.SERBIAN,
    LangCodeEnum.SLOVAKIAN,
    LangCodeEnum.SLOVENIAN,
    LangCodeEnum.SPANISH,
    LangCodeEnum.SPANISH_MEXICAN,
    LangCodeEnum.SWAHILI,
    LangCodeEnum.SWEDISH,
    LangCodeEnum.TAMIL,
    LangCodeEnum.TETUN,
    LangCodeEnum.TSONGA,
    LangCodeEnum.TURKISH,
    LangCodeEnum.UKRAINIAN,
    LangCodeEnum.URDU_PAKISTAN,
    LangCodeEnum.VIETNAMESE,
    LangCodeEnum.WELSH,
    LangCodeEnum.XHOSA,
  ],

  dashboardLanguages: [
    // 0. Default
    { code: 'en_GB', title: 'English (UK)' },

    // 1. Master Project
    { code: 'cs_CZ', title: 'Czech' },
    { code: 'da_DK', title: 'Danish' },
    { code: 'nl_NL', title: 'Dutch (Netherlands)' },
    { code: 'fr_FR', title: 'French' },
    { code: 'de_DE', title: 'German' },
    { code: 'es_ES', title: 'Spanish (Spain)' },
    { code: 'cy_GB', title: 'Welsh' },

    // Bundle 1
    { code: 'hr_HR', title: 'Croatian' },
    { code: 'fi_FI', title: 'Finnish' },
    { code: 'tl_PH', title: 'Filipino' },
    { code: 'hu_HU', title: 'Hungarian' },
    { code: 'id_ID', title: 'Indonesian' },
    { code: 'it_IT', title: 'Italian' },
    { code: 'ja_JP', title: 'Japanese' },

    // Bundle 2
    { code: 'ko_KR', title: 'Korean' },
    { code: 'lv_LV', title: 'Latvian' },
    { code: 'lt_LT', title: 'Lithuanian' },
    { code: 'no_NO', title: 'Norwegian' },
    { code: 'pl_PL', title: 'Polish' },
    { code: 'pt_BR', title: 'Portuguese (Brazil)' },
    { code: 'pt_PT', title: 'Portuguese (Portugal)' },

    // Bundle 3
    { code: 'ro_RO', title: 'Romanian' },
    { code: 'ru_RU', title: 'Russian' },
    { code: 'sr_RS', title: 'Serbian' },
    { code: 'sk_SK', title: 'Slovak' },
    { code: 'sl_SI', title: 'Slovene' },
    { code: 'es_MX', title: 'Spanish (Mexican)' },
    { code: 'sv_SE', title: 'Swedish' },

    // Bundle 4
    { code: 'bs_BA', title: 'Bosnian' },
    { code: 'zh_CN', title: 'Chinese Simplified' },
    { code: 'zh_TW', title: 'Chinese Traditional' },
    { code: 'gu_IN', title: 'Gujarati' },
    { code: 'hi_IN', title: 'Hindi' },
    { code: 'tr_TR', title: 'Turkish' },
    { code: 'uk_UA', title: 'Ukrainian' },
    { code: 'vi_VN', title: 'Vietnamese' },

    // Bundle 5
    { code: 'bn_BD', title: 'Bengali' },
    { code: 'bg_BG', title: 'Bulgarian' },
    { code: 'ga_IE', title: 'Irish' },
    { code: 'mg_MG', title: 'Malagasy' },
    { code: 'my_MM', title: 'Burmese' },
    { code: 'nl_BE', title: 'Dutch (Belgium)' },
    { code: 'pa_IN', title: 'Punjabi' },
  ],

  // Below array represents real language codes how they should be used in report-ui
  // dashboardLanguages: [
  //   // 0. Default
  //   { code: LangCodeEnum.ENGLISH_BRITISH, title: 'English (UK)' },
  //   // 1. Master Project
  //   { code: 'bs', title: 'Bosnian' },
  //   { code: 'bg', title: 'Bulgarian' },
  //   { code: 'cy', title: 'Welsh' },
  //   { code: 'hr', title: 'Croatian' },
  //   { code: 'cs', title: 'Czech' },
  //   { code: 'da', title: 'Danish' },
  //   { code: 'nl', title: 'Dutch' },

  //   // Bundle 1
  //   { code: 'fi', title: 'Finnish' },
  //   { code: 'fr', title: 'French' },
  //   { code: 'de', title: 'German' },
  //   { code: 'hu', title: 'Hungarian' },
  //   { code: 'id', title: 'Indonesian' },
  //   { code: 'it', title: 'Italian' },
  //   { code: 'ja', title: 'Japanese' },
  //   { code: 'tl', title: 'Tagalog (Filipino)' },

  //   // Bundle 2
  //   { code: 'ko', title: 'Korean' },
  //   { code: 'lv', title: 'Latvian' },
  //   { code: 'lt', title: 'Lithuanian' },
  //   { code: 'no', title: 'Norwegian' },
  //   { code: 'fa', title: 'Persian' },
  //   { code: 'pl', title: 'Polish' },
  //   { code: 'pt', title: 'Portuguese' },
  //   { code: 'pt-BR', title: 'Portuguese (Brazil)' },

  //   // Bundle 3
  //   { code: 'ro', title: 'Romanian' },
  //   { code: 'ru', title: 'Russian' },
  //   { code: 'sr', title: 'Serbian (Cyrillic)' },
  //   { code: 'sk', title: 'Slovak' },
  //   { code: 'sl', title: 'Slovenian' },
  //   { code: 'es', title: 'Spanish' },
  //   { code: 'es-MX', title: 'Spanish (Mexican)' },
  //   { code: 'sv', title: 'Swedish' },

  //   // Bundle 4
  //   { code: 'zh-Hans', title: 'Chinese (Simplified)' },
  //   { code: 'zh-TW', title: 'Chinese (Traditional)' },
  //   { code: 'gu', title: 'Gujarati (India)' },
  //   { code: 'hi', title: 'Hindi (India)' },
  //   { code: 'tr', title: 'Turkish' },
  //   { code: 'uk', title: 'Ukrainian' },
  //   { code: 'vi', title: 'Vietnamese' },

  //   // Bundle 5
  //   { code: 'bn', title: 'Bengali' },
  //   { code: 'ga', title: 'Irish' },
  //   { code: 'mg', title: 'Malagasy' },
  //   { code: 'my', title: 'Myanmar (Burmese)' },
  //   { code: 'nl-BE', title: 'Dutch (Belgium)' },
  //   { code: 'pa', title: 'Punjabi' },
  // ],
};
