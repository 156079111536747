import { createPaginationQuery } from 'util/createPaginationQuery';

import { CompanyApi } from 'api/CompanyApi';
import { ProjectApi } from 'api/ProjectApi';
import { List } from 'immutable';
import { CompanyMapper } from 'mapper/CompanyMapper';
import { PaginationMapper } from 'mapper/PaginationMapper';
import { ProjectMapper } from 'mapper/ProjectMapper';
import { Company } from 'model/Company';
import { Pagination } from 'model/Pagination';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { ProjectSortBy } from 'model/ProjectSortBy';
import { ProjectStatus } from 'model/ProjectStatus';
import { SortDirection } from 'shared/constants';

export type ProjectQuery = PaginationQuery & {
  searchText: string;
  sortBy: ProjectSortBy;
  status: ProjectStatus | null;
};

export type PaginationQuery = {
  page: number;
};

export class ProjectRepository {
  constructor(
    private projectApi: ProjectApi,
    private projectMapper: ProjectMapper,
    private paginationMapper: PaginationMapper,
    private companyMapper: CompanyMapper,
    private companyApi: CompanyApi
  ) {}

  list({
    page,
    limit,
    searchText,
    sortBy,
    status,
    circleId,
  }: {
    page: number;
    limit?: number;
    searchText: string;
    sortBy: ProjectSortBy;
    status?: ProjectStatus;
    circleId?: string;
  }): Promise<{ list: List<Project>; pagination: Pagination }> {
    const sort = sortBy === ProjectSortBy.LastModified ? 'updatedAt' : 'liveStart';
    const query = createPaginationQuery({
      page,
      search: searchText,
      filters: {
        status,
        'company.circleId': circleId,
      },
      limit,
      sort: {
        [sort]: SortDirection.DESC,
      },
    });

    return this.projectApi.list(query).then((result) => {
      return {
        list: List(result._embedded.items.map((entry) => this.projectMapper.deserialize(entry))),
        pagination: this.paginationMapper.deserialize(result),
      };
    });
  }

  get(projectId: number): Promise<Project> {
    return this.projectApi.get(projectId).then((entry) => this.projectMapper.deserialize(entry));
  }

  async create(
    { company, kind }: { company: Company; kind: ProjectKind },
    options: { logo?: File }
  ): Promise<Project> {
    // If company ID already exit use it
    // If not create company to obtain companyId for later request
    const companyId = company.getId()
      ? company.getId()
      : await this.companyApi
          .create(this.companyMapper.serialize(company), options.logo)
          .then((entry) => entry.id);

    return this.projectApi
      .create({
        kind,
        companyId: Number(companyId), // TODO this should be a number investigate
      })
      .then((entry) => this.projectMapper.deserialize(entry));
  }

  reopen(liveStop: any, projectId: number): Promise<Project> {
    return this.projectApi
      .reopen(liveStop, projectId)
      .then((entry) => this.projectMapper.deserialize(entry));
  }

  update({
    company,
    projectId,
    logo,
    ssoEnabled,
    multiLanguageEnabled,
    customKioskCodesEnabled,
    aiSummariesEnabled,
  }: {
    company: Company;
    projectId: number;
    logo?: File;
    ssoEnabled?: boolean;
    multiLanguageEnabled?: boolean;
    customKioskCodesEnabled?: boolean;
    aiSummariesEnabled?: boolean;
  }): Promise<Project> {
    return this.projectApi
      .update({
        company: this.companyMapper.serialize(company),
        projectId,
        logo,
        ssoEnabled,
        multiLanguageEnabled,
        customKioskCodesEnabled,
        aiSummariesEnabled,
      })
      .then((entry) => this.projectMapper.deserialize(entry));
  }

  updateSpecificField({
    company,
    projectId,
    fieldName,
    value,
  }: {
    company: Company;
    projectId: number;
    fieldName: string;
    value: boolean;
  }): Promise<Project> {
    return this.projectApi
      .updateSpecific({
        company: this.companyMapper.serialize(company),
        projectId,
        fieldName,
        value,
      })
      .then((entry) => this.projectMapper.deserialize(entry));
  }

  close(projectId: number): Promise<Project> {
    return this.projectApi.close(projectId).then((entry) => this.projectMapper.deserialize(entry));
  }

  delete(projectId: number): Promise<void> {
    return this.projectApi.delete(projectId);
  }

  createFirstPage(projectId: number): Promise<Project> {
    return this.projectApi
      .createFirstPage(projectId)
      .then((entry) => this.projectMapper.deserialize(entry));
  }

  publish(projectId: number, generateNewAiInsights: boolean): Promise<void> {
    return this.projectApi.publish(projectId, generateNewAiInsights);
  }
}
