import { List } from 'immutable';

export const isArrayEmpty = (item: readonly any[] | List<any>): boolean => {
  if (!item) {
    return true;
  }

  if (item instanceof List) {
    return (item as List<any>).size === 0;
  } else {
    return (item as any[]).length === 0;
  }
};
