export enum AdminModal {
  AddClientAdmin = 'AddClientAdmin',
  AddDemographic = 'AddDemographic',
  AddDepartment = 'AddDepartment',
  AddSSOProviderDomain = 'AddSSOProviderDomain',
  AddUser = 'AddUser',
  AutomatedEmail = 'AutomatedEmail',
  BenchmarkData = 'BenchmarkData',
  ChangeCompanyTimezone = 'ChangeCompanyTimezone',
  ChangeDepartmentParent = 'ChangeDepartmentParent',
  ChangeDepartmentPosition = 'ChangeDepartmentPosition',
  ChangeSurveyInviteTimeConfirmation = 'ChangeSurveyInviteTimeConfirmation',
  CloseLiveProjectConfirmation = 'CloseLiveProjectConfirmation',
  CloseProject = 'CloseProject',
  ConnectedDepartments = 'ConnectedDepartments',
  ContactList = 'ContactList',
  CopyOdDemandPoll = 'CopyOnDemandPoll',
  CreateClient = 'CreateClient',
  CreateProject = 'CreateProject',
  CreateProjectTemplate = 'CreateProjectTemplate',
  CustomCategory = 'CustomCategory',
  DashboardSettings = 'DashboardSettings',
  DeleteAccount = 'DeleteAccount',
  DeleteAnswerGroup = 'DeleteAnswerGroup',
  DeleteClientAdmin = 'DeleteClientAdmin',
  DeleteCustomCategory = 'DeleteCustomCategory',
  DeleteDemographic = 'DeleteDemographic',
  DeleteDepartment = 'DeleteDepartment',
  DeleteEmployee = 'DeleteEmployee',
  DeletePageConfirmation = 'DeletePageConfirmation',
  DeletePermissionGroup = 'DeletePermissionGroup',
  DeleteProject = 'DeleteProject',
  DeleteQuestionConfirmation = 'DeleteQuestionConfirmation',
  DeleteSSOProviderDomain = 'DeleteSSOProviderDomain',
  DeleteUser = 'DeleteUser',
  DeliveryStatistic = 'DeliveryStatistic',
  DemographyExternalEventApiEnabledModal = 'DemographyExternalEventApiEnabledModal',
  DepartmentExternalEventApiEnabledModal = 'DepartmentExternalEventApiEnabledModal',
  DistributeLifecycle = 'DistributeLifecycle',
  EmailTemplate = 'EmailTemplate',
  EmployeeForm = 'EmployeeForm',
  EmployeesDryUpload = 'EmployeesDryUpload',
  ImportSurvey = 'ImportSurvey',
  LifecycleAudienceUploadedConfirmation = 'AudienceUploadedConfirmation',
  MoveQuestion = 'MoveQuestion',
  PreviewSurvey = 'PreviewSurvey',
  PreviousSurvey = 'PreviousSurvey',
  PreviousTemplate = 'PreviousTemplate',
  PulseSurveyFrequency = 'PulseSurveyFrequency',
  Question = 'Question',
  QuestionLibrary = 'QuestionLibrary',
  QuestionMultiLanguage = 'QuestionMultiLanguage',
  ReUploadEmployees = 'ReUploadEmployees',
  RespondentExternalEventApiEnabledModal = 'RespondentExternalEventApiEnabledModal',
  ReopenProject = 'ReopenProject',
  ScheduleModal = 'ScheduleModal',
  SoftWarningEmployeeUpload = 'SoftWarningEmployeeUpload',
  SuccessfulEmployeesUpload = 'SuccessfulEmployeesUpload',
  TextOrMedia = 'TextOrMedia',
  TextOrMediaMultiLanguage = 'TextOrMediaMultiLanguage',
  UploadUsers = 'UploadUsers',
}
